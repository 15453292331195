@import '../../utils/styles/colors.scss';

.background{
  display: flex;
  flex-direction: row;
    height: 300px;
    background-color: $primary;
    width: 100%;
    margin-top: 250px;

}



.logo{
margin: 50px;
  width: 140px;
  height: 130px;
}
@media (max-width: 450px) {
  .logo{
    margin: 50px;
      width: 140px;
    }
}
.copyrate{
    color:   $tertiary;
}

.responsive-logo{
  width: 10.9%;
  height: 100%;
}

.wrapper-footer-content{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

footer a {
  text-decoration: none;
  color: $secondary;
}

footer a:hover {
  color: white;
}

