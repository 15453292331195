@import '../../utils/styles/colors.scss';

.dropdown-title{
  width: 50%;
}

@media (max-width: 450px) {
  .dropdown-title{
    width: 100%;
    text-align: center;
  }
}

.wrapper-dropdown{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper-underTitle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.container-dropdown{
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: solid black 1px;
}



.dropdown-content{
  margin-left: 50px;
}

.wrapper-underTitle p, span{
  font-weight: bold;
}

.dropdown-content p{
  text-align: justify;
}