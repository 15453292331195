@import "../../utils/styles/colors.scss";

.container-confidentialite{
  margin-left: 450px;
  margin-right: 450px;
  margin-top: 120px;
}

.container-confidentialite h1 {
  padding-top: 100px;
}