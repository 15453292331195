@import "../../utils/styles/colors.scss";

.wraper-page {
  display: flex;
  justify-content: center;
  margin-top: 130px;
}

.container-insurance-form {
  display: flex;
  width: 65%;
  min-height: 600px;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  border: $secondary solid 1px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .container-insurance-form {
    width: 90%;
  }
}

.form-section {
  margin-bottom: 20px;
}
.form-section h2 {
  text-align: center;
}

label {
  display: block;
  font-size: 16px;
  margin: 20px;
  font-weight: bold;
}

input[type="date"],
input[type="text"],
input[type="email"],
input[type="tel"],
select {
  width: 93%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 10px 30px 10px 30px;
}


@media (max-width: 768px) {
  input[type="date"],
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  select {
    width: 88%;
    margin-left: 20px;
  }
}



input[type="radio"] {
  margin-left: 30px;
}

input[type="radio"] {
  width: auto;
  margin-right: 10px;
}

input[type="radio"] + label {
  display: inline-block;
  margin-right: 20px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
select {
  font-size: 14px;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  background: $secondary;
  border: none;
  border-radius: 15px 15px 15px 15px;
  font-size: 20px;
  font-weight: bold;
  color: $tertiary;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  margin: 20px;
}

.container-text-tarif a:hover {
  background: $hover;
  color: $primary;
}

.wraper-bouton {
  display: flex;
  flex-direction: row;
}


textarea {
  width: 90%;
  height: 150px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  margin: 20px;
}

@media (max-width: 768px) {
  textarea {
    width: 88%;
    
  }
}



.error {
  color: red;
  margin-left: 20px;
}

button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.7;
}
