@import "../../utils/styles/colors.scss";

.Container-page-login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    margin-top: 130px;
}

.Container-login {
    position: relative;
    width: 380px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.Container-login h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.input-box {
    position: relative;
    margin-top: 20px;
}

.input-box input {
    width: 93% !important;
    padding: 15px !important;
    border: 1px solid #ddd !important;
    border-radius: 5px !important;
    font-size: 1em !important;
    transition: border 0.3s !important;
    outline: none !important;
    margin: 0px !important;
    box-sizing: content-box !important;
}

.input-box input:focus {
    border-color: #45f3ff; // Couleur de mise au point
}

.input-box span {
    position: absolute;
    left: 10px;
    top: 10px;
    color: #888;
    font-size: 0.85em;
    transition: 0.3s;
}

.input-box input:focus ~ span,
.input-box input:valid ~ span {
    top: -18px;
    left: 5px;
    color: $secondary;
    font-size: 0.75em;
}

.btn {
    width: 92%;
    margin: 12px;
    border: none;
    border-radius: 5px;
    background: $secondary;
    color: #fff;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

.btn:hover {
    background: rgb(240, 181, 94);
}

.btn:active {
    transform: scale(0.98);
}

.error {
    color: red;
    margin-top: 10px;
    text-align: center;
}

