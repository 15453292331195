@import '../../utils/styles/colors.scss';

.carousel-container {
  width: 100%;
  overflow: hidden;
  padding: 20px 0;

  .card-carousel-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .card-carousel--nav__left,
    .card-carousel--nav__right {
      position: absolute;
      top: 50%;
      width: 50px;
      height: 50px;
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      z-index: 1;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border: solid 15px $secondary;
        border-width: 5px 5px 0 0;
        transform: rotate(45deg);
      }

      &--disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .card-carousel--nav__left {
      left: 10px;

      &::before {
        transform: rotate(-135deg);
      }
    }

    .card-carousel--nav__right {
      right: 10px;
    }

    .card-carousel {
      width: 100%;
      overflow: hidden;

      .card-carousel--overflow-container {
        display: flex;
        transition: transform 0.5s ease;
      }

      .card-carousel-cards {
        display: flex;
        transition: transform 0.5s ease;
      }

      .card-carousel--card {
        min-width: 360px;
        margin: 10px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 550px;

        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
        }

        .card-carousel--card--footer {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding: 15px;
          text-align: center;


          h3 {
            font-size: 1.2rem;
            color: #333;

            span {
              color: $secondary;
            }
          }

          p {
            font-size: 1rem;
            color: #555;
            height: 60px;
          }

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 40px;
            background: $secondary;
            border: none;
            border-radius: 15px 15px 15px 15px;
            font-size: 20px;
            font-weight: bold;
            color: $tertiary;
            box-shadow: 2px 0 5px rgba(0,0,0,0.5);
            transition: background-color 0.3s ease, color 0.3s ease;
            cursor: pointer;
            margin-top: 40px;
            text-decoration: none;

            &:hover {
              background:  $hover;
              color:  $primary;
            }
          }
        }
      }
    }
  }
}

/* Responsive for tablet - One card at a time */
@media (max-width: 1024px) {

  .carousel-container {
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .card-carousel--card {
      min-width: 100%; /* Make the card take full width */
      margin: 0; /* Remove margins to fill the screen */
      height: 600px !important; /* Set height to 100% of viewport */
      box-sizing: border-box; /* Ensure padding and borders are included in width/height */

      .card-carousel--card--footer {
        padding: 20px;
        h3 {
          font-size: 1.1rem;
        }

        p {
          font-size: 1rem;
        }

        a {
          padding: 20px 30px;
          margin-top: 80px !important;
        }
      }
    }
  }
}

/* Responsive for mobile - One card at a time */
@media (max-width: 768px) {
  .carousel-container {
    .card-carousel--nav__left,
    .card-carousel--nav__right {
      width: 40px;
      height: 40px;

      &::before {
        width: 15px;
        height: 15px;
      }
    }

    .card-carousel--card {
      min-width: 100%; /* Ensure the card takes full width of the screen */
      margin: 0; /* Remove margins */
      height: 100%; /* Set height to fill viewport */
      display: flex;
      flex-direction: column;

      img {
        flex-grow: 1;
        object-fit: cover;
        height: 50px;
      }

      .card-carousel--card--footer {
        padding: 15px;

        h3 {
          font-size: 1rem;
        }

        p {
          font-size: 0.9rem;
        }

        a {
          padding: 8px 16px;
          font-size: 0.9rem;
        }
      }
    }
  }
}
