@import "../../utils/styles/colors.scss";

.table-container {
  
  max-width: 1000px;
  margin: 130px auto; // Consolidation des marges
  padding-top: 20px;

  h1 {
    padding-top: 20px; // Ajout de l'espacement pour le titre
  }

  .client-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 2px 0 5px rgba(0,0,0,0.5);

    th, td {
      padding: 10px;
      border: 1px solid #ddd;
      text-align: left;
    }
 
    th {
      background-color: $secondary;
    }

    a {
      text-align: center;
      display: block;
      width: 80%;
      padding: 5px 10px;
      background-color: $secondary; // Assurez-vous que cette variable est définie
      color: white;
      border: none;
      cursor: pointer;
      box-shadow: 2px 0 5px rgba(0,0,0,0.5);
      text-decoration: none; // Pour enlever le soulignement par défaut
      border-radius: 5px;
      &:hover {
        background-color: $hover; // Assurez-vous que cette variable est définie
      }
    }
  }


  .Background-detail{
    background-color: rgba(188, 155, 35, 0.2862745098);
  }

  .pagination {
    margin: 70px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      margin: 0 5px;
      padding: 8px 16px;
      cursor: pointer;
      border: 1px solid #ddd;
      background-color: rgb(0, 0, 0);
      color: white;
      text-decoration: none; // Pour enlever le soulignement par défaut
     

      &.active {
        background-color: #4CAF50;
        color: rgb(255, 0, 0);
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }

  .pagination, span {
    margin: 20px;
  }
}

@media (max-width: 1100px) {
  
  .pagination {
  padding-bottom: 110px;
  }
}