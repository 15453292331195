@import "../../utils/styles/colors.scss";



.container-insurance{
  display: flex;
  width: 90%;
  height: 600px;
  
  align-items: center;
  justify-content: center;
  margin: 100px;
}

.container-choice-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container-choice-form a{
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: bold;
  border: 1px solid black;
  border-radius: 15px;
  margin-right: 20px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.5);
}

.container-choice-form a:hover{
  background: $hover;
}

.container-choice-form img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

.container-information h3{
  font-size: 13px;
}

.container-information h2{
  font-size: 35px;
  color: $secondary;
}

.container-information p{
  margin-top: 50px;
  font-size: 18px;
}

.container-information{
  width: 500px;
  margin-right: 150px;
}

.tarif-button a{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  background: $secondary;
  border: none;
  border-radius: 15px 15px 15px 15px;
  font-size: 20px;
  font-weight: bold;
  color: $tertiary;
  box-shadow: 2px 0 5px rgba(0,0,0,0.5);
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  margin-top: 40px;
  text-decoration: none;
}

.tarif-button a:hover{
  background:  $hover;
  color:  $primary;
}

@media (max-width: 1400px) {
  .container-insurance{
    flex-direction: column;
    height: 1000px;
  }

  .container-information{
    margin-bottom: 150px;
  }

  .container-estimate{
    width: auto;
    height: auto;
    justify-content: end;
  }

  .container-choice-form {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    height: 100%;
    margin-right: 80px;
  }

  .container-choice-form a{
    margin: 20px 20px 0px 0px;
  }
}

@media (max-width: 768px) {
  .container-insurance{
    flex-direction: column;
    height: 100%;
    margin: 0px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .container-information{
    width: 80%;
    margin: 200px 0px 0px 0px;
  }

  .container-estimate{
    width: 80%;
    
  }

  .container-choice-form {
    margin-right: 0px;
  }
}