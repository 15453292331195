@import "../../utils/styles/colors.scss";

.container-contact{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 800px;
 margin-top: 130px;
}

.container-text-contact{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 500px;
  text-align: center;
 }

 .container-text-contact span{
    color: $secondary;
 }



 

 .container-cards-contact{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }

 
 .container-cards-contact h2{
  color: black;
  margin-top: 100px;
  margin-bottom: 5px;
 }
 
 .container-cards-contact h3{
   color: black;
   margin-top: 5px;
   margin-bottom: 5px;
 }

 .card-contact{
   background-color: $secondary;
   width: 500px;
   height: 250px;
   display: flex;
   flex-direction: row;
   margin-top: 50px;
   border-radius: 20px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
 }




 .decoration-card-contact{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 200px;
   height: 100%;
   background-color: black;
   border-radius: 20px 0px 70px 20px;
 }

 .wrapper-content{
   display: flex;
   flex-direction: column;
   margin: 25px;
   justify-content: center;
 }

 .wrapper-content p{
   margin: 5px 0px 5px 0px;
   color: $tertiary;
 }

 .wrapper-content h3{
   color: $tertiary;
 }

 .wrapper-content a{
   text-decoration: none;
   color: black;
   font-weight: bold;
 }

 .wrapper-content a:hover{
   color: $tertiary;
 }

 .asterix{
   font-size: 9px;
 }

 @media (max-width: 1100px) {
  .card-contact{
    width: 90%;
  }

  .container-contact{

    height: 1300px;
    margin-top: 130px;
   }

   .container-cards-contact h2{
    margin-top: 200px;
   }
}

.logoCard{
  width: 120px;
  height: 120px;
}