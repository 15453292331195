@import "../../../utils/styles/colors.scss";

// File: Details.scss

.Container-questionnaire-detail {
    list-style-type: none; /* Supprime les puces par défaut */
    padding: 0; /* Supprime le padding par défaut */
    margin: 70px; /* Ajoute un espace autour de la liste */
  
    li {
      background-color: #bc9b2349; /* Fond légèrement gris */
      border: 1px solid #ddd; /* Bordure légère */
      border-radius: 5px; /* Coins arrondis */
      padding: 15px; /* Espacement interne */
      margin-bottom: 10px; /* Espacement entre les éléments de la liste */
      transition: background-color 0.3s; /* Effet de transition pour le hover */
  
     
  
      strong {
        color: #333; /* Couleur du texte en gras */
      }
    }
  }
  
 .title-detail-client{
    font-size: 24px; /* Taille du texte pour le titre */
    color: $secondary; /* Couleur du titre */
    margin-bottom: 20px; /* Espacement en dessous du titre */
    margin-top: 120px;
    padding-top: 90px;
    margin-left: 70px;
  }
  