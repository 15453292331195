@import '../../utils/styles/colors.scss';

nav {
  display: flex;
  position: fixed;
  background: $primary;
  width: 100%;
  height: 130px;
  z-index: 10;
  box-shadow: 0px 0 30px rgba(0,0,0,0.5);
  border-radius: 0 0 2.5rem 0;
  top: 0px;
}

@media (max-width: 1024px) {
  nav {
    width: 100%;
    border-radius: 0 !important;
  }
}


.container-logo {
  width: 62%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .container-logo {
    width: 90%;
    margin-right: 170px;
  }
}

@media (max-width: 768px) {
  .container-logo {
    width: 90%;
    margin-right: 80px;
  }
}


.logo-nav {
  width: 140px;
  height: 100%;
}

.burger {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .burger {
    width: 40%;
  }
}

.burger span {
  width: 35px;
  border-bottom: 2px solid  $tertiary;
  padding-top: 9px;
}

.burger p {
  color: $tertiary;
  font-size: 15px;
  margin: 1px;
}

.menu-toggle {
  font-size: 30px;
  cursor: pointer;
  padding: 10px;
  position: fixed;
  top: 10px;
  left: 10px;
}


.menu {
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: fixed;
  z-index: 5;
  width: 350px;
  height:88%;
  background: rgb(17, 17, 17);
  color: $tertiary;
  opacity: 0;
  box-shadow: 2px 0 5px rgba(0,0,0,0.5);
}




.menu ul h2{
  font-size: 16px;
  color: $secondary;
}

.menu ul {
  margin-left: 30px;
  list-style-type: none;
  padding: 0;
  margin-bottom: 40px;
}

.menu ul li {
  padding: 7px 40px;
  font-size: 16px;
}

.menu ul li a {
  color: $tertiary;
  text-decoration: none;
}

.menu ul li:hover {
  background-color: $hover;
}


.animate__animated.animate__fadeInLeft {
  --animate-duration: 0.4s;
}

.shadowMenu{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500%;
  height: 110%;
  background-color: rgba(0, 0, 0, 0.582);
  position: fixed;
  top: -60px;
  left: 350px;
  cursor: pointer;
}



.navbar-contact{
  display: flex;
  align-items: center;
  background-color: $primary;
  margin-top: 0px;
  width: 100%;
  height: 150px;
}


.navbar-contact ul{
  width: 100%;
}




.link-contact{
  font-size: 22px;
}

.icon-navbar{
  font-size: 23px;
  margin-right: 10px;
  color: $secondary;
}

@media (max-width: 1024px) {
  .shadowMenu{
    width: 0%;
    height:0%;
  }
  
  .navbar-contact{
    display: flex;
    align-items: center;
    background-color: $primary;
    margin-top: 0px;
    width: 100%;
  }
}


.scroll-container {
  max-height: 1600px; /* Adjust the height as per your requirement */
  overflow-y: auto; /* Adds vertical scrollbar */
  margin-left: 30px;
  margin-bottom: 30px;
  margin-top: 50px;
}

.scroll-container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.scroll-container h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.align-page-menu{
  display: block;
}


@media (max-width: 1024px) {
  .menu {
    width: 100%;
    height:100%;
    justify-content: space-around;
    align-items: center;
    margin-top: 0px;
  }
}


@media (max-width: 768px) {
  .menu {
    width: 100%;
    top: 0px;
    justify-content: end;
    margin-top: 0px;
  }
  .scroll-container {
    margin-top: 150px;
  }
}