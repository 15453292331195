@import "../../utils/styles/colors.scss";

.container-tarif {
  display: flex;
  background: url("../../assets/image/Baniere-Tarif.png") no-repeat center center; /* Centre l'image */
  background-size: cover; /* L'image couvre tout le conteneur */
  width: 100%;
  height: 600px;
  border-radius: 0px 0px 100px 0px;
  margin-top: 130px;
}

@media (max-width: 1770px) {
  
  .container-tarif {
    background:linear-gradient(rgba(255, 255, 255, 0.185), rgba(255, 255, 255, 0.164)), url("../../assets/image/Baniere-Tarif.png") no-repeat center center;
  }
}

.container-tarif img {
  width: 0%;
  height: 0%;
 
}

.container-text-tarif {
  margin-left: 280px;
  margin-top: 120px;
  width: 650px;
}

.container-text-tarif h1 {
  color: $secondary;
}

.container-text-tarif h1 span {
    font-size: 40px;
    color: black;
  }

.container-text-tarif p{
    font-size: 18px;
    color: black;
}

.icon-tcheck{
    color: $secondary;
    font-size: 22px;
}


.container-text-tarif a, .button-tarif{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    background: $secondary;
    border: none;
    border-radius: 15px 15px 15px 15px;
    font-size: 20px;
    font-weight: bold;
    color: $tertiary;
    box-shadow: 2px 0 5px rgba(0,0,0,0.5);
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    text-decoration: none;
}



@media (max-width: 1100px) {
  .container-text-tarif {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0px;
    margin-top: 0px;
    width: 80%;
  }
  
  .container-tarif {
    background: url("");
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;


  }
}



.container-text-tarif a:hover, .button-tarif:hover{
    background:  $hover;
    color:  $primary;
}


.container-categorie{
  width: 130px;
}

.container-get-tarif-pro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  bottom: 30px;
  box-shadow: 0px 15px 40px rgba(188, 156, 35, 0.24);
  border-radius: .75rem;
  background-color: $tertiary;
  width: 1100px;
  height: 100px;
  margin-bottom: 30px;
  margin-right: 50%;
  margin-left: 50%;
}

.container-get-tarif{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  bottom: 30px;
  box-shadow: 0px 15px 40px rgba(188, 156, 35, 0.24);
  border-radius: .75rem;
  background-color: $tertiary;
  width: 1050px;
  height: 100px;
  margin-bottom: 30px;
  margin-right: 50%;
  margin-left: 50%;
}

@media (max-width: 1400px) {
  .container-get-tarif, .container-get-tarif-pro{
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 0px;
    width: 70%;
    height: 100%;
    margin-bottom: 36px;
    margin-top: 50px;
  }
}


.title-particular{
  font-size: 20px;
  margin-left: 50px;
  color: $secondary;
  width: 20%;
}



@media (max-width: 1400px) {
  .container-get-tarif h2{
    margin-left: 0px;
    width: 100%;
    text-align: center;
  }
  
}
.container-get-tarif a,.container-get-tarif-pro a{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .80rem;
  margin-left: 35px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 1400px) {
  .container-get-tarif a, .container-get-tarif-pro a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .80rem;
    margin-left: 0;
    text-decoration: none;
    color: black;
    width: 40%;
    text-align: center;
  }

  .container-categorie{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 150px;
  }
}



@media (max-width: 768px) {
  .container-get-tarif a{
    width: 60%;
    margin-top: 20px;
  }
}

.container-get-tarif span{
  height: 50px;
  border-right: solid 1px rgba(0, 0, 0, 0.356);
  margin-left: 50px
}

@media (max-width: 1400px) {
  .container-get-tarif span{
    height: 0px;
    display: none;
  }
  
}


.icon-tarif{
  font-size: 30px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.493);
}

.icon-tarif:hover{
  color: rgb(0, 0, 0);
}






.container-gold-info h2{
  margin-bottom: 50px;
  margin-top: 100px;
}


.container-gold-info{
  position: relative;
  display: flex;
  flex-direction: column;
  left: 10%;
  width: 80%;
  height: 400px;
  margin: 110px 0 110px 0px;
  align-items: center;
}

@media (max-width: 1100px) {
  .container-gold-info{
   height: 100%;
  }
}

.wrapper-critere{
  display: flex;
  flex-direction: row;
}

@media (max-width: 1100px) {
  .wrapper-critere{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.container-critere{
  width: 20%;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  text-align: center
}

@media (max-width: 1100px) {
  .container-critere{
    margin-left: 0px;
    margin-bottom: 20px;
    width: 60%;
  }
}

.container-critere h3{
  font-size: .875rem;
  color:  $secondary;
}

.container-gold-info h2 span{
  color:  $secondary;
}

.container-critere p{
  font-size: .75rem;
  margin: 0px;
}

.wrapper-critere span{
  margin-top: 20px;
  height: 100px;
  border-right: solid 1px rgba(0, 0, 0, 0.356);
  margin-left: 50px
}

@media (max-width: 1100px) {
  .wrapper-critere span{
    height: 0px;
  }
}

.icon-info{
  font-size: 50px;
  color: #4e6841;
}


/* Dropdown*/

.section-dropdown{
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 10%;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 180px;
}


.wrapper-content-dropdown{
  display: flex;
  flex-direction: column;
  width: 50%;
}

.dropdown-title span{
  color:  $secondary;
}

@media (max-width: 1100px) {
  .section-dropdown{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    left: 0%;
  }

  .wrapper-content-dropdown{
    width: 90%;
  }

  

}










.section-cards{
  position: relative;
  width: 80%;
  left: 10%;
  align-items: center;
  margin-top: 100px;
}

.container-cards{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 180px;
  margin-bottom: 150px;
}

@media (max-width: 1100px) {
  .container-cards{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}


.cards{
  display: flex;
  flex-direction: column;
  width: 28%;
  height: 450px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 100px 0px;
  background-size: cover;
  background-position: center;
  margin-right: 50px;
}

@media (max-width: 1100px) {
  .cards{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 100px;
    margin-right: 0px;
  }
}





@media (max-width: 1100px) {
  .container-cards img{
    width: 100%;
    height: 30%;
  }
}

.shadow-image{
  position: absolute;
  width: 28%;
  height: 450px;
  background: rgba(0, 0, 0, 0.685);
  z-index: -1;
  border-radius: 0px 0px 100px 0px;
}

@media (max-width: 1100px) {
  .shadow-image{
    width: 100%;
    height: auto;
  }
}



.container-content h2{
  margin-bottom: 10px;
  margin-left: 20px;
  font-size: 22px;
  color:  $tertiary;
}

.container-content p{
  margin-left: 20px;
  margin-bottom: 90px;
  color:  $tertiary;
  height: 60px;
  font-size: 16px;
  font-weight: bold;
}

  .container-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-color: rgba(0, 0, 0, 0.377);
    margin: 0;
    padding: 0;
    border-radius: 0px 0px 100px 0px;
  }



.container-content a{
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  color:  $tertiary;
  font-weight: bold;
  border: 2px solid  $tertiary;
  border-radius: 15px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 20px;
}

.container-content a:hover{

  color: $secondary;
  background-color:  $tertiary;
}

.containeurTarif{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.container-tarif-pro {
  display: flex;
  width: 100%;
  height: 600px;
  border-radius: 0px 0px 100px 0px;
  margin-top: 0px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.container-tarif-pro h1{
  background-color: rgba(255, 255, 255, 0.315);
  margin-left: 15px;
  margin-right: 15px;
}


.container-tarif-pro img {
  display: flex;
  width: 63%;
  height: 600px;
  margin-left: 50px;
  border-radius: 0px 0px 100px 0px;
  box-shadow: -4px 0px 15px rgba(0, 0, 0, 0.2);
}

.container-text-tarif-pro {
  margin-left: 150px;
  margin-top: 150px;
  width: 820px;
}

.container-text-tarif-pro h2 {
  color: $secondary;
  font-size: 30px;
}

.container-text-tarif-pro h2 span {
    font-size: 30px;
    color: black;
  }

.container-text-tarif-pro p{
    font-size: 18px;
    color: black;
}


.container-text-tarif-pro a, .button-tarif{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  background: $secondary;
  border: none;
  border-radius: 15px 15px 15px 15px;
  font-size: 20px;
  font-weight: bold;
  color: $tertiary;
  box-shadow: 2px 0 5px rgba(0,0,0,0.5);
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  text-decoration: none; 
}



@media (max-width: 1100px) {
.container-text-tarif-pro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 0px;
  margin-top: 0px;
  width: 70%;
  margin-left: 50px;
  margin-right: 50px;
}

.container-tarif-pro {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 0px 0px;
  margin-top: 130px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  
}

.container-tarif-pro img {
 display: none;
}

.container-text-tarif-pro a, .button-tarif{
  margin-left: 0px;
  margin-bottom: 50px;
}
}



@media (max-width: 768px) {

  .container-tarif-pro {
    flex-direction: column;
    width: 90%;
    margin-left: 18px;
  }
}

.container-text-tarif-pro a:hover, .button-tarif:hover{
  background:  $hover;
  color:  $primary;
  
}

.section-pro{
  color: $secondary;
  font-size: 40px;
  margin: 30px 0px 30px 0px;
  position: absolute;
  text-align: center;
  width: 100%;
}

@media (max-width: 1100px) {
  .section-pro{
    color: $secondary;
    font-size: 40px;
    margin: 30px 0px 30px 0px;
    position: relative;
    text-align: center;
    width: 100%;
  }
  }
  

.title-pro{
  width: 10%;
  font-size: 20px;
  color: $secondary;
  width: 17%;
  margin-left: 20px;
}

