* {
     font-family: sans-serif;
}

body{
    margin: 0px;
    background-color: #FFFFFF;
}


h1{
    font-size: 35px;
}

.align-page{
    width: 100%;
}